import { request } from "@/requests";

export const showAllExtractFeedbackByPage = (data) => {
    return request({
        url: '/information/extractFeedback/showAllExtractFeedbackByPage',
        method: 'post',
        data
    })
}

export const deleteExtractFeedback = (data) => {
    return request({
        url: '/information/extractFeedback/deleteExtractFeedback',
        method: 'post',
        data
    })
}

export const showAllExtractFeedbackByContentId = (data) => {
    return request({
        url: '/information/extractFeedback/showAllExtractFeedbackByContentId/' + data,
        method: 'get',
    })
}

export const auditExtractFeedback = (data) => {
    return request({
        url: '/information/extractFeedback/auditExtractFeedback',
        method: 'post',
        data
    })
}
