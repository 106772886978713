import { request } from "@/requests";

export const selectOperationLogInfo = (data) => {
    return request({
        url: 'system/sysOperLog/selectOperLog',
        method: 'post',
        data:data
    })
}

export const cleanAllOperationInfo = () => {
    return request({
        url: '/system/sysOperLog/cleanOperLog',
        method: 'get'
    })
}

export const batchDeleteLog = (data) => {
    return request({
        url: '/system/sysOperLog/deleteOperLogByIds',
        method: 'post',
        data
    })
}


export const selectOperLogById = (data) => {
    return request({
        url: '/system/sysOperLog/selectOperLogById/' + data,
        method: 'get'
    })
}