import { request } from "@/requests";

export const selectAllUserByPage = (data) => {
    return request({
        url: '/system/sysUser/selectAllUserByPage',
        method: 'post',
        data:data
    })
}

// 查询当前用户的基本信息
export const selectUserBaseInfo = () => {
    return request({
        url: '/system/sysUser/getUserInfo',
        method: 'get',
    })
}

// 添加用户
export const addUser = (data) => {
    return request({
        url: '/system/sysUser/addUser',
        method: 'post',
        data
    })
}

// 批量删除用户
export const deleteUser = (data) => {
    return request({
        url: '/system/sysUser/deleteUser',
        method: 'post',
        data
    })
}

// 编辑用户信息
export const updateUser = (data) => {
    return request({
        url: '/system/sysUser/updateUser',
        method: 'post',
        data
    })
}