<template>
  <div class="pagination-wrapper">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="paginationData.currentPage"
      :page-sizes="[10, 20, 30, 40,50,100]"
      :page-size="paginationData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paginationData.total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props:{
    paginationData:{
      type:Object,
      default:{}
    }
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.$emit("changePaginationSize", val);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.$emit("changePaginationNum", val);
    },
  },
};
</script>

<style scoped>
.pagination-wrapper {
  width: calc(100% - 20px);
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
</style>