import { request } from "@/requests";

// 获取情感信息占比---饼图
export const selectContentExtractCountByTimeInterval = (data) => {
    return request({
        url: '/information/originalContentExtract/selectContentExtractCountByTimeInterval',
        method: 'post',
        data: data
    })
}

// 获取每天的情感数据以及日期----折线图
export const selectContentExtractDayCountByTimeInterval = (data) => {
    return request({
        url: '/information/originalContentExtract/selectContentExtractDayCountByTimeInterval',
        method: 'post',
        data: data
    })
}

// 获取媒体活跃度----饼图
export const selectSourceAndProportionByTimeInterval = (data) => {
    return request({
        url: '/information/original_content/selectSourceAndProportionByTimeInterval',
        method: 'post',
        data: data
    })
}

// 获取媒体走势----折线图
export const selectSourceAndCountVoByTimeInterval = (data) => {
    return request({
        url: '/information/original_content/selectSourceAndCountVoByTimeInterval',
        method: 'post',
        data: data
    })
}

// 获取地域分布图
export const selectRegionCountByTimeInterval = (data) => {
    return request({
        url: '/information/originalContentExt/selectRegionCountByTimeInterval',
        method: 'post',
        data: data
    })
}

// 获取词云
export const selectWordCloudByTimeInterval = (data) => {
    return request({
        url: '/information/original_content/selectWordCloudByTimeInterval/' + data,
        method: 'get'
    })
}
