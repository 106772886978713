<template>
  <div class="data-statistics-all-wrapper">
    <div class="data-statistics-wrapper">
      <div class="row-wrapper">
        <div class="content-wrapper">
          <div id="pieChart1" style="width: 400px; height: 400px"></div>
        </div>
        <div class="content-wrapper">
          <div id="StackedLineChart1" style="width: 400px; height: 400px"></div>
        </div>
        <div class="content-wrapper">
          <div id="funnelChart" style="width: 400px; height: 400px">
            <div class="keyWord-title">关键词云</div>
            <img
              :src="require('../assets/keyWord.jpg')"
              style="width: 400px"
            />
          </div>
        </div>
      </div>
      <div class="row-wrapper" style="margin-top: 20px">
        <div class="content-wrapper">
          <div id="pieChart2" style="width: 400px; height: 400px"></div>
        </div>
        <div class="content-wrapper">
          <div id="StackedLineChart2" style="width: 400px; height: 400px"></div>
        </div>
        <div class="content-wrapper">
          <div id="mapChart" style="width: 400px; height: 400px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import { getDay } from "@/utils/util";
import {
  selectContentExtractCountByTimeInterval,
  selectContentExtractDayCountByTimeInterval,
  selectSourceAndProportionByTimeInterval,
  selectSourceAndCountVoByTimeInterval,
  selectRegionCountByTimeInterval,
} from "@/api/dataStatistics";
import chinaMap from "@/utils/chainMap.json";
export default {
  data() {
    return {
      pieChart1: [],
      mapDate: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getStackedLineChartData2();
      this.getPieChartData1();
      this.getStackedLineChartData1();
      this.getPieChartData2();
      this.getMapData();
    });

    // this.getKeyWord();
  },
  methods: {
    async getPieChartData1() {
      await selectContentExtractCountByTimeInterval({
        startTime: "",
        endTime: "",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.pieChart1 = [];
          const data = res.data; // Modify this according to your API response structure
          let dataArray = [];
          let positive = {
            name: "正面",
            value: data.positiveCount,
          };
          dataArray.push(positive);
          let negative = {
            name: "负面",
            value: data.negativeCount,
          };
          dataArray.push(negative);
          let neutral = {
            name: "中性",
            value: data.neutralCount,
          };
          dataArray.push(neutral);
          this.pieChart1 = dataArray;
          this.renderPieChart1(dataArray);
        }
      });
    },
    async getStackedLineChartData1() {
      await selectContentExtractDayCountByTimeInterval({
        startTime: "",
        endTime: "",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          let array = [];
          let positiveArray = [];
          let negativeArray = [];
          let neutralArray = [];
          let timeArray = [];
          const data = res.data;
          data.forEach((item) => {
            positiveArray.push(item.positiveCount);
            negativeArray.push(item.negativeCount);
            neutralArray.push(item.neutralCount);
            timeArray.push(getDay(item.publishDate));
          });
          array.push(positiveArray);
          array.push(negativeArray);
          array.push(neutralArray);
          array.push(timeArray);
          let Xdistance;
          console.log(array);
          if (timeArray.length <= 7) {
            Xdistance = 0;
          } else if (timeArray.length > 7 && timeArray.length <= 15) {
            Xdistance = 2;
          } else if (timeArray.length > 15 && timeArray.length <= 30) {
            Xdistance = 5;
          } else {
            Xdistance = 13;
          }
          this.renderStackedLineChart1(array, Xdistance);
        }
      });
    },
    renderPieChart1(dataArray) {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("pieChart1"));

      // 设置饼图的option
      let options = {
        title: {
          text: "声誉信息占比",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de"],
        series: [
          {
            name: "声誉信息占比",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold",
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: dataArray,
          },
        ],
      };
      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    renderStackedLineChart1(array, Xdistance) {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("StackedLineChart1"));

      // 设置饼图的option
      let options = {
        title: {
          text: "声誉信息走势",
          left: "center",
        },
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.2)",
          show: true,
          trigger: "axis",
          textStyle: {
            color: "#ffffff",
            textShadowBlur: 10, // 重点
            textShadowColor: "transparent", // 重点
          },
          axisPointer: {
            lineStyle: {
              color: "#2877ED",
            },
          },
        },
        grid: {
          left: "5%", // 左内边距
          right: "5%", // 右内边距
          top: "10%", // 上内边距
          bottom: 0, // 下内边距
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: array[3],
          axisTick: {
            inside: true,
            show: true,
          },
          axisLabel: {
            interval: Xdistance, // Display all labels without hiding any
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            interval: 0, // 显示所有标签，不隐藏任何标签
          },
        },
        color: ["#95D887", "#FF6A53", "#2877ED"], // Set colors for the series

        series: [
          {
            name: "正面",
            type: "line",
            stack: "Total1",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#95D887", // Color for "正面" series
              },
            },
            data: array[0],
            // data:[0, 48, 1125, 63, 111, 85, 222, 197, 208, 478, 232, 88, 1070, 464, 262, 418, 686, 156, 165, 142, 82, 101, 28]
          },
          {
            name: "负面",
            type: "line",
            stack: "Total2",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#FF6A53", // Color for "负面" series
              },
            },
            data: array[1],
            // data:[0, 20, 20, 30, 22, 15, 11, 24, 52, 202, 502, 488, 348, 868, 740, 102, 20, 38, 28, 24, 17, 24, 5]
          },
          {
            name: "中性",
            type: "line",
            stack: "Total3",
            symbol: "none",
            itemStyle: {
              normal: {
                color: "#2877ED", // Color for "中性" series
              },
            },
            data: array[2],
            // data:[2, 557, 1018, 425, 363, 515, 551, 882, 626, 866, 426, 548, 862, 776, 1014, 968, 728, 620, 623, 1024, 452, 326, 77]
          },
        ],
      };
      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    async getPieChartData2() {
      await selectSourceAndProportionByTimeInterval({
        startTime: "",
        endTime: "",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          const data = res.data;
          for (let i = 0; i < data.length; i++) {
            data[i].value = data[i].captureWebsiteCount;
            data[i].name = data[i].captureWebsite;
          }
          this.renderPieChart2(data);
        }
      });
    },
    async getStackedLineChartData2() {
      await selectSourceAndCountVoByTimeInterval({
        startTime: "",
        endTime: "",
      }).then((res) => {
        console.log("测试");
        console.log(res);
        if (res.code == 200) {
          console.log(res);
          const data = res.data;
          let dataArray = [];
          let timeArray = [];
          let lendgeArray = [];
          for (let i = 0; i < data.length; i++) {
            let obj = {};
            lendgeArray.push(data[i].captureWebsite);
            let specificData = [];
            obj.type = "line";
            obj.symbol = "none";
            obj.name = data[i].captureWebsite;
            obj.stack = "Total" + i;
            data[i].dataCountList.forEach((item) => {
              specificData.push(item.count);
            });
            obj.data = specificData;
            dataArray.push(obj);
            if (i == 0) {
              data[i].dataCountList.forEach((item) => {
                timeArray.push(getDay(item.data));
              });
            }
          }
          let Xdistance;
          if (timeArray.length <= 7) {
            Xdistance = 0;
          } else if (timeArray.length > 7 && timeArray.length <= 15) {
            Xdistance = 2;
          } else if (timeArray.length > 15 && timeArray.length <= 30) {
            Xdistance = 5;
          } else {
            Xdistance = 13;
          }
          console.log(dataArray);
          console.log(Xdistance);
          console.log(lendgeArray);
          console.log(timeArray);
          this.renderStackedLineChart2(
            dataArray,
            Xdistance,
            lendgeArray,
            timeArray
          );
        }
      });
    },
    renderPieChart2(dataArray) {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("pieChart2"));

      // 设置饼图的option
      let options = {
        title: {
          text: "媒体活跃度",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ",
        },
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#5087ec",
        ],
        series: [
          {
            name: "媒体活跃度",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
              },
            },
            emphasis: {
              scale: false,
            },
            labelLine: {
              show: false,
            },
            data: dataArray, // Use the data passed to the function
          },
        ],
      };
      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    renderStackedLineChart2(dataArray, Xdistance, lendgeArray, timeArray) {
      // 初始化echarts实例
      let myChart = echarts.init(document.getElementById("StackedLineChart2"));

      // 设置饼图的option
      let options = {
        title: {
          text: "媒体活跃走势",
          left: "center",
        },
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.2)",
          show: true,
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "#2877ED",
            },
          },
          textStyle: {
            color: "#ffffff",
            textShadowBlur: 10, // 重点
            textShadowColor: "transparent", // 重点
          },
        },
        color: [
          "#2877ed",
          "#3eacdf",
          "#54bba5",
          "#95D887",
          "#FF6A53",
          "#FFB55B",
        ],
        grid: {
          left: "2.5%", // 左内边距
          right: "5%", // 右内边距
          top: "35%", // 上内边距
          bottom: 0, // 下内边距
          containLabel: true,
        },
        legend: {
          // data: ['正面', '负面', '中性'],
          data: lendgeArray,
          top: "15%", // 上内边距
          orient: "horizontal",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: timeArray,
          axisTick: {
            inside: true,
            show: true,
          },
          axisLabel: {
            interval: Xdistance, // Display all labels without hiding any
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            interval: 0, // 显示所有标签，不隐藏任何标签
          },
        },
        series: dataArray,
      };
      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
    // async getKeyWord() {
    //   await selectWordCloudByTimeInterval(30).then((res) => {
    //     console.log(res);
    //   });
    // },
    async getMapData() {
      await selectRegionCountByTimeInterval({
        startTime: "",
        endTime: "",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.mapDate = [];
          const data = res.data;
          for (let i = 0; i < data.length; i++) {
            data[i].value = data[i].regionCount;
            if (data[i].region === "台湾" || data[i].region === "香港" || data[i].region === "澳门") {
              data[i].name = data[i].region + "特别行政区";
            } else if(data[i].region === "天津" || data[i].region === "北京" || data[i].region === "上海" || data[i].region === "重庆"){
              data[i].name = data[i].region + "市";
            }else if(data[i].region === "西藏" || data[i].region === "内蒙古"){
              data[i].name = data[i].region + "自治区";
            }else if(data[i].region === "新疆"){
              data[i].name = data[i].region + "维吾尔自治区";
            }else if(data[i].region === "广西"){
              data[i].name = data[i].region + "壮族自治区";
            }else if(data[i].region === "宁夏"){
              data[i].name = data[i].region + "回族自治区";
            }else{
              data[i].name = data[i].region + "省";
            }
          }
          this.mapDate = data;
          this.mapDate.push({
            name: "南海诸岛",
            region: "南海诸岛",
            regionCount: 0,
            value: 0,
          });
          console.log(this.mapDate);
          this.initChartMap();
        }
      });
    },
    initChartMap() {
      console.log(this.mapDate);
      // 初始化echarts实例
      echarts.registerMap("china", chinaMap);
      let myChart = echarts.init(document.getElementById("mapChart"));

      // 设置饼图的option
      let options = {
        title: {
          text: "地域分布图",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "#FFF",
          padding: [10, 15, 8, 15],
          extraCssText: "box-shadow: 2px 2px 10px rgba(21, 126, 245, 0.35);",
          textStyle: {
            fontFamily:
              "'Microsoft YaHei', Arial, 'Avenir', Helvetica, sans-serif",
            color: "#005dff",
            fontSize: 16,
            textShadowBlur: 10,
            textShadowColor: "transparent",
          },
          formatter: `{b} :  {c}条`,
        },
        geo: [
          {
            map: "china",
            roam: false,
            aspectScale: 0.8,
            layoutCenter: ["50%", "43%"],
            layoutSize: 300,
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 1)",
                  fontSize: "6",
                },
              },
              emphasis: {
                color: "#333",
              },
            },
            itemStyle: {
              normal: {
                borderColor: "rgba(0,0,0,0.2)",
                areaColor: "#005dff",
              },
            },
          },
        ],
        visualMap: {
          min: 0,
          type: "continuous",
          left: "left",
          top: "bottom",
          text: ["高", "低"],
          calculable: false,
          itemWidth: 10,
          itemHeight: 100,
          color: ["#2877ed", "#3981ee", "#85b1f2", "#d4e3f6", "#f5f8fa"],
        },
        series: [
          {
            type: "map",
            mapType: "china",
            geoIndex: 0,
            roam: false,
            label: {
              normal: {
                show: true,
              },
              emphasis: {
                show: true,
              },
            },
            data: this.mapDate,
          },
        ],
      };
      // 使用设置好的option绘制图表
      myChart.setOption(options);
    },
  },
};
</script>

<style scoped>
.data-statistics-all-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data-statistics-wrapper {
  width: calc(100% - 70px);
  height: calc(100% - 70px);
  padding: 15px;
  background-color: #ffffff;
}
.row-wrapper {
  width: 100%;
  height: calc(50%);
  display: flex;
}

.content-wrapper {
  width: 33.33%; /* 让每个容器占据父容器的1/3宽度 */
  height: 100%;
  padding: 20px;
  box-sizing: border-box; /* 让边框计算在内 */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#funnelChart {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.keyWord-title {
  font-size: 18px;
  color: #333;
  font-weight: bolder;
  margin-bottom: 50px;
}
</style>