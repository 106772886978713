<template>
  <div class="home_wrapper">
    <el-container style="width: 100%; height: 100%">
      <el-aside width="isCollapse?'60px':'300px'">
        <el-menu
          class="el-menu-vertical-demo"
          style="height: 100%"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          :router="true"
          :default-active="$route.path"
        >
          <el-menu-item index="/userManagement">
            <i class="el-icon-user-solid"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>
          <el-menu-item index="/dataStatistics">
            <i class="el-icon-menu"></i>
            <span slot="title">数据统计</span>
          </el-menu-item>
          <el-menu-item index="/feedback">
            <i class="el-icon-s-ticket"></i>
            <span slot="title">用户反馈</span>
          </el-menu-item>
          <el-submenu index="/loginLog">
            <template slot="title">
              <i class="el-icon-s-order"></i>
              <span>日志管理</span>
            </template>
            <el-menu-item index="/loginLog">登录日志</el-menu-item>
            <el-menu-item index="/OperationLog">操作日志</el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header-left-icon-wrapper">
            <i
              class="el-icon-s-fold"
              v-if="!isCollapse"
              @click="isCollapseClick"
            ></i>
            <i
              class="el-icon-s-unfold"
              v-if="isCollapse"
              @click="isCollapseClick"
            ></i>
          </div>
          <div class="header-right-select-wrapper">
            <el-dropdown @command="handleCommand">
              <el-avatar size="large" :src="circleUrl"></el-avatar>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command="1">个人信息</el-dropdown-item> -->
                <el-dropdown-item command="2">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { selectUserBaseInfo } from "@/api/user";
import { logout } from "@/api/login";
export default {
  data() {
    return {
      isCollapse: false,
      userInfo: {},
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    handleCommand(command) {
      // 根据 command 的值来确定用户选择了哪个菜单项
      switch (command) {
        // case "1":
        //   this.$router.push({
        //     path: "/userInfo",
        //   });
        //   break;
        case "2":
          logout().then((res) => {
            console.log(res);
            if (res.code == 200) {
              localStorage.clear();
              this.$router.replace({
                name: "login",
              });
            }
          });
          break;
        default:
          break;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    isCollapseClick() {
      this.isCollapse = !this.isCollapse;
    },
    async getUserInfo() {
      await selectUserBaseInfo().then((res) => {
        console.log("基本信息");
        console.log(res);
        if (res.code == 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      });
    },
  },
};
</script>

<style scoped>
.home_wrapper {
  width: 100%;
  height: 100%;
}
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 60px;
  padding: 0;
  width: 100%;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-right-select-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.el-dropdown {
  height: 100%;
  display: flex;
  align-items: center;
}

.el-aside {
  height: 100%;
}

.el-main {
  padding: 0px;
}

.el-menu-vertical-demo {
  background-color: #304156 !important;
}

:deep(.el-menu) {
  background-color: #304156 !important;
}

:deep(.el-submenu__title) {
  color: #bfcbd9;
}

:deep(.el-menu-item) {
  color: #bfcbd9;
}

:deep(.el-submenu__title:hover, .el-menu-item:hover) {
  background-color: #263445 !important;
}

:deep(.el-menu-item:hover) {
  background-color: #263445 !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

body > .el-container {
  margin-bottom: 40px;
}

.header-left-icon-wrapper {
  font-size: 25px;
}

:deep(.el-menu-item.is-active) {
  color: #409ee4 !important;
  background-color: transparent;
}

.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>