<template>
  <div class="user-management-all-wrapper">
    <div class="user-management-wrapper">
      <div class="search-condition-wrapper">
        <div class="search-condition">
          <div class="search-condition-title">手机号：</div>
          <el-input
            placeholder="请输入手机号"
            v-model="searchQueryData.phoneNumber"
          ></el-input>
        </div>
        <div class="search-condition">
          <el-button
            type="primary"
            size="small"
            style="margin: 0 20px"
            @click="searchDataClick"
            >查询</el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="add-user-wrapper">
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="small"
          style="margin: 0 20px"
          @click="addNewUserClick"
          >新建</el-button
        >
        <el-button
          icon="el-icon-delete"
          type="danger"
          size="small"
          @click="deleteBatchUserClick"
          >批量删除</el-button
        >
      </div>
      <div class="table-wrapper">
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column prop="nickname" label="用户名" width="150">
          </el-table-column>
          <el-table-column prop="phoneNumber" label="手机号" width="150">
          </el-table-column>
          <el-table-column prop="companyName" label="组织名称">
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间"> </el-table-column>
          <el-table-column prop="endTime" label="结束时间"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                @click="handleViewClick(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleeditClick(scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handledeleteClick(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
    </div>
    <el-dialog
      :title="dialogFormTitle"
      @closed="closedFormDialogHandle"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          prop="nickname"
          label="用户名"
          :label-width="formLabelWidth"
          required
        >
          <el-input
            style="width: 220px"
            v-model="form.nickname"
            placeholder="请输入用户名"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="phoneNumber"
          label="手机号码"
          :label-width="formLabelWidth"
          required
        >
          <el-input
            style="width: 220px"
            v-model="form.phoneNumber"
            placeholder="请输入手机号码"
            :disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="startTime"
          label="开始时间"
          :label-width="formLabelWidth"
          required
        >
          <el-date-picker
            v-model="form.startTime"
            type="date"
            :disabled="disabled"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          prop="endTime"
          label="结束时间"
          :label-width="formLabelWidth"
          required
        >
          <el-date-picker
            v-model="form.endTime"
            type="date"
            :disabled="disabled"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input
            type="textarea"
            v-model="form.remark"
            :disabled="disabled"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancleFormHandle">取 消</el-button>
        <el-button type="primary" @click="sureFormHandle">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationView from "@/components/PaginationView.vue";
import {
  selectAllUserByPage,
  addUser,
  deleteUser,
  updateUser,
} from "@/api/user";
export default {
  data() {
    return {
      searchQueryData: {
        pageNum: 1,
        pageSize: 10,
        phoneNumber: "",
      },
      flag: 0,
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      tableData: [],
      dialogFormVisible: false,
      disabled: false,
      form: {
        userId: 0,
        companyId: 0,
        phoneNumber: "",
        startTime: "",
        endTime: "",
        remark: "",
        nickname: "",
      },
      rules: {
        phoneNumber: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        nickname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          // {
          //   min: 2,
          //   max: 10,
          //   message: "长度在 2 到 10 个字符",
          //   trigger: "blur",
          // },
        ],
        startTime: [
          { required: true, message: "请选择日期", trigger: "blur" },
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        endTime: [
          { required: true, message: "请选择日期", trigger: "blur" },
          {
            type: "date",
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      formLabelWidth: "120px",
      dialogFormTitle: "",
      userInfo: {},
      multipleSelection: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getUserListData();
  },
  components: {
    paginationView,
  },
  methods: {
    changePaginationSize(val) {
      this.searchQueryData.pageSize = val;
      this.getUserListData();
    },
    changePaginationNum(val) {
      this.searchQueryData.pageNum = val;
      this.getUserListData();
    },
    searchDataClick() {
      this.searchQueryData.pageNum = 1;
      this.searchQueryData.pageSize = 10;
      this.getUserListData();
    },
    resetClick() {
      this.searchQueryData.phoneNumber = "";
      this.searchQueryData.pageNum = 1;
      this.searchQueryData.pageSize = 10;
      this.getUserListData();
    },
    async getUserListData() {
      await selectAllUserByPage(this.searchQueryData).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    addNewUserClick() {
      this.dialogFormTitle = "新增用户";
      this.dialogFormVisible = true;
      this.flag = 3;
    },
    handleViewClick(row) {
      this.dialogFormTitle = "查看用户信息";
      this.dialogFormVisible = true;
      console.log(row);
      this.disabled = true;
      this.form = { ...row };
      this.form.startTime = new Date(this.form.startTime);
      this.form.endTime = new Date(this.form.endTime);
      this.flag = 1;
    },
    handleeditClick(row) {
      this.dialogFormTitle = "编辑用户信息";
      this.dialogFormVisible = true;
      console.log(row);
      this.disabled = false;
      this.form = { ...row };
      this.form.startTime = new Date(this.form.startTime);
      this.form.endTime = new Date(this.form.endTime);
      this.flag = 2;
    },
    handledeleteClick(row) {
      this.multipleSelection = [];
      this.multipleSelection.push(row);
      this.deleteUser();
    },
    async deleteUser() {
      this.$confirm("是否要删除此用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let array = [];
          this.multipleSelection.forEach((item) => {
            array.push(item.userId);
          });
          await deleteUser(array).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message({ type: "success", message: "删除成功" });
              this.searchQueryData.pageNum = 1;
              this.searchQueryData.pageSize = 10;
              this.getUserListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteBatchUserClick() {
      this.deleteUser();
    },
    cancleFormHandle() {
      this.dialogFormVisible = false;
    },
    sureFormHandle() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.flag == 3) {
            this.addUserMethon();
          } else if (this.flag == 2) {
            console.log("1111111");
            this.editUserMethon();
          } else if (this.flag == 1) {
            this.dialogFormVisible = false;
          }
        }
      });
    },
    async editUserMethon() {
      this.form.startTime = this.$moment(this.form.startTime).format(
        "YYYY-MM-DD 00:00:00"
      );
      this.form.endTime = this.$moment(this.form.endTime).format(
        "YYYY-MM-DD 23:59:59"
      );
      console.log(this.form);
      await updateUser(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.searchQueryData.pageNum = 1;
          this.searchQueryData.pageSize = 10;
          this.getUserListData();
          this.dialogFormVisible = false;
        }
      });
    },
    async addUserMethon() {
      this.form.startTime = this.$moment(this.form.startTime).format(
        "YYYY-MM-DD 00:00:00"
      );
      this.form.endTime = this.$moment(this.form.endTime).format(
        "YYYY-MM-DD 23:59:59"
      );
      this.form.companyId = this.userInfo.companyId;
      await addUser(this.form).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.searchQueryData.pageNum = 1;
          this.searchQueryData.pageSize = 10;
          this.getUserListData();
          this.dialogFormVisible = false;
        }
      });
    },
    closedFormDialogHandle() {
      this.form = {
        userId: 0,
        companyId: 0,
        phoneNumber: "",
        startTime: "",
        endTime: "",
        remark: "",
      };
    },
  },
};
</script>

<style scoped>
.user-management-all-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-management-wrapper {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #ffffff;
}

.search-condition-wrapper {
  width: calc(100% - 30px);
  padding: 15px;
  display: flex;
  align-items: center;
}

.search-condition {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search-condition-title {
  white-space: nowrap; /* 防止换行 */
  margin-right: 5px;
}

.table-wrapper {
  width: calc(100% - 30px);
  padding: 15px;
  height: 75%;
  overflow-y: scroll;
}
</style>