<template>
  <div class="user-management-all-wrapper">
    <div class="user-management-wrapper">
      <div class="search-condition-wrapper">
        <div class="search-condition">
          <div class="search-condition-title">状态：</div>
          <el-select v-model="searchQueryData.isAudit" placeholder="请选择">
            <el-option
              v-for="item in auditStatus"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search-condition">
          <el-button
            type="primary"
            size="small"
            style="margin: 0 20px"
            @click="searchDataClick"
            >查询</el-button
          >
          <el-button size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="add-user-wrapper">
        <el-button
          icon="el-icon-delete"
          type="danger"
          size="small"
          style="margin: 0 20px"
          @click="deleteBatchUserClick"
          >批量删除</el-button
        >
      </div>
      <div class="table-wrapper">
        <el-table
          @selection-change="handleSelectionChange"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            type="index"
            width="50"
            label="序号"
          ></el-table-column>
          <el-table-column prop="title" label="标题" width="150">
          </el-table-column>
          <el-table-column prop="url" label="原文链接" width="300">
            <template slot-scope="scope"
              ><a :href="scope.row.url" target="_blank">{{
                scope.row.url
              }}</a></template
            >
          </el-table-column>
          <el-table-column prop="companyName" label="原始属性">
            <template slot-scope="scope">
              <span v-if="scope.row.oldSentiment == 2">负面、</span>
              <span v-if="scope.row.oldSentiment == 0">中性、</span>
              <span v-if="scope.row.oldSentiment == 1">正面、</span>
              <span v-if="scope.row.oldRelated == 1">相关</span>
              <span v-if="scope.row.oldRelated == 0">不相关</span>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="反馈属性">
            <template slot-scope="scope">
              <span v-if="scope.row.newSentiment == 2">负面、</span>
              <span v-if="scope.row.newSentiment == 0">中性、</span>
              <span v-if="scope.row.newSentiment == 1">正面、</span>
              <span v-if="scope.row.newRelated == 1">相关</span>
              <span v-if="scope.row.newRelated == 0">不相关</span>
            </template>
          </el-table-column>
          <el-table-column prop="auditNickname" label="审核人">
          </el-table-column>
          <el-table-column prop="isAudit" label="审核状态">
            <template slot-scope="scope">
              <span v-if="scope.row.isAudit == 0">未审核</span>
              <span v-if="scope.row.isAudit == 1">已审核</span>
            </template>
          </el-table-column>
          <el-table-column prop="applyNickname" label="反馈用户">
          </el-table-column>
          <el-table-column prop="auditTime" label="申请时间"> </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                @click="handleViewClick(scope.row)"
                type="text"
                size="small"
                >查看记录</el-button
              >
              <el-button
                type="text"
                size="small"
                v-if="scope.row.isAudit == 0"
                @click="handleAuditClick(scope.row)"
                >审核</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handledeleteClick(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <paginationView
        :paginationData="paginationData"
        @changePaginationSize="changePaginationSize"
        @changePaginationNum="changePaginationNum"
      ></paginationView>
      <el-dialog
        title="反馈记录"
        @open="openDialog"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <div class="feedback-record-dialog-wrapper">
          <div
            class="feedback-record-dialog"
            v-for="(item, index) in recordList"
            :key="index"
          >
            <div class="feedback_record_details_firstLine_wrapper">
              <div class="feedback_record_details_firstLine_title">
                用户反馈
              </div>
              <div
                class="feedback_record_details_firstLine_result"
                style="background-color: #e3f5df"
                v-if="item.isPass == 1"
              >
                审核通过
              </div>
              <div
                class="feedback_record_details_firstLine_result"
                style="background-color: #ffdbd6"
                v-if="item.isPass == 0"
              >
                审核不通过
              </div>
            </div>
            <div
              class="feedback_record_details_secondLine_wrapper"
              style="margin-top: 15px"
            >
              <div class="feedback_record_details_secondLine_title">
                相关性：
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldRelated == 1"
              >
                相关
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldRelated == 0"
              >
                不相关
              </div>
              <div class="feedback_record_details_secondLine_result_jiantou">
                <i class="el-icon-right"></i>
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.newRelated == 1"
              >
                相关
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newRelated == 0"
              >
                不相关
              </div>
            </div>
            <div
              class="feedback_record_details_secondLine_wrapper"
              style="margin-top: 10px"
            >
              <div class="feedback_record_details_secondLine_title">
                信息属性：
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 1"
              >
                正面
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 0"
              >
                中性
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 2"
              >
                负面
              </div>
              <div class="feedback_record_details_secondLine_result_jiantou">
                <i class="el-icon-right"></i>
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 1"
              >
                正面
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 0"
              >
                中性
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 2"
              >
                负面
              </div>
            </div>
            <div class="feedback_record_details_thirdLine_wrapper">
              <div class="feedback_record_reviewedBy">
                {{ item.auditNickname }}
              </div>
              <div class="jiange"></div>
              <div class="feedback_record_reviewedTime">
                {{ item.auditTime }}
              </div>
            </div>
          </div>
          <div v-if="NoauditDataListPass.length > 0" style="margin: 20px">
            最新反馈信息：
          </div>
          <div
            class="feedback-record-dialog"
            v-for="(item, index) in NoauditDataListPass"
            :key="index"
          >
            <div class="feedback_record_details_firstLine_wrapper">
              <div class="feedback_record_details_firstLine_title">
                用户反馈
              </div>
            </div>
            <div
              class="feedback_record_details_secondLine_wrapper"
              style="margin-top: 15px"
            >
              <div class="feedback_record_details_secondLine_title">
                相关性：
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldRelated == 1"
              >
                相关
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldRelated == 0"
              >
                不相关
              </div>
              <div class="feedback_record_details_secondLine_result_jiantou">
                <i class="el-icon-right"></i>
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.newRelated == 1"
              >
                相关
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newRelated == 0"
              >
                不相关
              </div>
            </div>
            <div
              class="feedback_record_details_secondLine_wrapper"
              style="margin-top: 10px"
            >
              <div class="feedback_record_details_secondLine_title">
                信息属性：
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 1"
              >
                正面
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 0"
              >
                中性
              </div>
              <div
                class="feedback_record_details_secondLine_result_old"
                v-if="item.oldSentiment == 2"
              >
                负面
              </div>
              <div class="feedback_record_details_secondLine_result_jiantou">
                <i class="el-icon-right"></i>
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 1"
              >
                正面
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 0"
              >
                中性
              </div>
              <div
                class="feedback_record_details_secondLine_result_new"
                v-if="item.newSentiment == 2"
              >
                负面
              </div>
            </div>
            <div class="feedback_record_details_thirdLine_wrapper">
              <div class="feedback_record_reviewedBy">
                {{ item.auditNickname }}
              </div>
              <div class="jiange"></div>
              <div class="feedback_record_reviewedTime">
                {{ item.auditTime }}
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="!isAudited">
          <el-button @click="approvedNo">不 通 过</el-button>
          <el-button type="primary" @click="approved">通 过</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import paginationView from "@/components/PaginationView.vue";
import {
  showAllExtractFeedbackByPage,
  deleteExtractFeedback,
  showAllExtractFeedbackByContentId,
  auditExtractFeedback,
} from "@/api/feedBack";
export default {
  data() {
    return {
      searchQueryData: {
        pageNum: 1,
        pageSize: 10,
        isAudit: "",
      },
      paginationData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageNum: 1,
      },
      auditStatus: [
        {
          value: 0,
          label: "未审核",
        },
        {
          value: 1,
          label: "已审核",
        },
      ],
      dialogVisible: false,
      tableData: [],
      userInfo: {},
      multipleSelection: [],
      currenId: "",
      recordList: [],
      NoauditDataListPass: [],
      isAudited: false,
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getUserListData();
  },
  components: {
    paginationView,
  },
  methods: {
    changePaginationSize(val) {
      this.searchQueryData.pageSize = val;
      this.getUserListData();
    },
    changePaginationNum(val) {
      this.searchQueryData.pageNum = val;
      this.getUserListData();
    },
    searchDataClick() {
      this.searchQueryData.pageNum = 1;
      this.searchQueryData.pageSize = 10;
      this.getUserListData();
    },
    resetClick() {
      this.searchQueryData.phoneNumber = "";
      this.searchQueryData.pageNum = 1;
      this.searchQueryData.pageSize = 10;
      this.getUserListData();
    },
    async getUserListData() {
      await showAllExtractFeedbackByPage(this.searchQueryData).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.paginationData.currentPage = res.data.pageNum;
          this.paginationData.pageSize = res.data.pageSize;
          this.paginationData.total = res.data.total;
        }
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },
    handleViewClick(row) {
      console.log(row);
      if (row.isAudit == 1) {
        this.isAudited = true;
      } else if (row.isAudit == 0) {
        this.isAudited = false;
      }
      this.dialogVisible = true;
      this.currenId = row.originalContentId;
      this.isAudited = true
    },
    handleAuditClick(row) {
      console.log(row);
      if (row.isAudit == 1) {
        this.isAudited = true;
      } else if (row.isAudit == 0) {
        this.isAudited = false;
      }
      this.dialogVisible = true;
      this.currenId = row.originalContentId;
      this.isAudited = false
    },
    handledeleteClick(row) {
      this.multipleSelection = [];
      this.multipleSelection.push(row);
      this.deleteUser();
    },
    async deleteUser() {
      this.$confirm("是否要删除此记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let array = [];
          this.multipleSelection.forEach((item) => {
            array.push(item.id);
          });
          await deleteExtractFeedback(array).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.$message({ type: "success", message: "删除成功" });
              this.searchQueryData.pageNum = 1;
              this.searchQueryData.pageSize = 10;
              this.getUserListData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    deleteBatchUserClick() {
      this.deleteUser();
    },
    async openDialog() {
      await showAllExtractFeedbackByContentId(this.currenId).then((res) => {
        console.log(res);
        if (res.code == 200) {
          const data = res.data;
          this.recordList = [];
          this.NoauditDataListPass = [];
          data.forEach((item) => {
            if (item.isAudit == 1) {
              this.recordList.push(item);
            } else if (item.isAudit == 0) {
              this.NoauditDataListPass.push(item);
            }
          });
        }
      });
    },
    async approved() {
      await auditExtractFeedback({
        contentId: this.currenId,
        isPass: 1,
      }).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.$message({
            type: 'success',
            message: '审核成功'
          })
          this.openDialog()
        }
      })
    },
    async approvedNo() {
      await auditExtractFeedback({
        contentId: this.currenId,
        isPass: 1,
      }).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.$message({
            type: 'success',
            message: '审核成功'
          })
          this.openDialog()
        }
      })
    },
  },
};
</script>

<style scoped>
.user-management-all-wrapper {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-management-wrapper {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background-color: #ffffff;
}

.search-condition-wrapper {
  width: calc(100% - 30px);
  padding: 15px;
  display: flex;
  align-items: center;
}

.search-condition {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search-condition-title {
  white-space: nowrap; /* 防止换行 */
  margin-right: 5px;
}

.table-wrapper {
  width: calc(100% - 30px);
  padding: 15px;
  height: 75%;
  overflow-y: scroll;
}

.feedback-record-dialog-wrapper {
  width: 100%;
}

.feedback-record-dialog {
  width: 100%;
  height: 130px;
  border-radius: 10px;
  box-shadow: 1px 1px 1px 1px #cecece;
  margin-bottom: 20px;
}

.feedback_record_details_firstLine_wrapper,
.feedback_record_details_secondLine_wrapper,
.feedback_record_details_thirdLine_wrapper {
  display: flex;
}

.feedback_record_details_firstLine_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback_record_details_firstLine_title {
  font-size: 15px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #0e1e35;
  line-height: 15px;
}

.feedback_record_details_firstLine_title {
  margin-top: 20px;
}

.feedback_record_details_firstLine_result {
  position: absolute;
  right: 0;
  font-size: 15px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #0e1e35;
  line-height: 15px;
  padding: 10px;
}

.feedback_record_details_secondLine_wrapper {
  margin-left: 32px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #0e1e35;
  line-height: 14px;
}

.feedback_record_details_secondLine_title {
  width: 30%;
}

.feedback_record_details_secondLine_result_old,
.feedback_record_details_secondLine_result_new {
  width: 20%;
}

.feedback_record_details_secondLine_result_jiantou {
  width: 20%;
}

.feedback_record_details_thirdLine_wrapper {
  margin-top: 15px;
  margin-bottom: 32px;
  margin-left: 32px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #4a5b6f;
  line-height: 14px;
  display: flex;
  align-items: center;
}

.jiange {
  width: 2px;
  height: 15px;
  background: #4a5b6f;
  border-radius: 0px 0px 0px 0rpx;
  opacity: 1;
  margin: 0 20px;
}

.new_feedback_record_details {
  background-color: #f1f5f8;
  border-radius: 20px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
}
</style>