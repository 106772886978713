<template>
  <div class="dialog-wrapper">
    <el-dialog
      title="操作日志详情"
      :visible.sync="dialogVisible"
      width="50%"
      @open="dialogOpen"
      :before-close="handleClose"
    >
      <div class="log-wrapper">
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作模块：</div>
            <div class="content">{{ info.title }}</div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">业务类型：</div>
            <div class="content">
              <div v-if="info.businessType == 0">其他</div>
              <div v-if="info.businessType == 1">新增</div>
              <div v-if="info.businessType == 2">修改</div>
              <div v-if="info.businessType == 3">删除</div>
              <div v-if="info.businessType == 4">授权</div>
              <div v-if="info.businessType == 5">导出</div>
              <div v-if="info.businessType == 6">导入</div>
              <div v-if="info.businessType == 7">强退</div>
              <div v-if="info.businessType == 8">生成代码</div>
              <div v-if="info.businessType == 9">清空数据</div>
            </div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">请求地址：</div>
            <div class="content">{{ info.operUrl }}</div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">请求方式：</div>
            <div class="content">
              <div class="content">{{ info.requestMethod }}</div>
            </div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作方法：</div>
            <div class="content">{{ info.method }}</div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作人员：</div>
            <div class="content">{{ info.operName }}</div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作类别：</div>
            <div class="content">
              <div v-if="info.operatorType == 0">其他</div>
              <div v-if="info.operatorType == 1">后台用户</div>
              <div v-if="info.operatorType == 2">手机端用户</div>
            </div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">主机地址：</div>
            <div class="content">{{ info.operIp }}</div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作地点：</div>
            <div class="content">{{ info.operLocation }}</div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">请求参数：</div>
            <div class="content">{{ info.operParam }}</div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">返回参数：</div>
            <div class="content">{{ info.jsonResult }}</div>
          </div>
        </div>
        <div class="line-wrapper">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作状态：</div>
            <div class="content">
              <div v-if="info.status == 0">正常</div>
              <div v-if="info.status == 1">异常</div>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">消耗时间：</div>
            <div class="content">{{ info.costTime }}</div>
          </div>
          <div class="content-wrapper">
            <div class="content-tile-wrapper">操作时间：</div>
            <div class="content">{{ info.operTime }}</div>
          </div>
        </div>
        <div class="line-wrapper" v-if="info.status == 1">
          <div class="content-wrapper">
            <div class="content-tile-wrapper">异常信息：</div>
            <div class="content">{{ info.errorMsg }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelClick">取 消</el-button>
        <el-button type="primary" @click="sureClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { selectOperLogById } from "@/api/operationLog";
export default {
  data() {
    return {
      info: {},
    };
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    operationLogId: {
      default: 0,
      type: Number,
    },
  },
  methods: {
    dialogOpen() {
      this.getOperationDetail();
    },
    async getOperationDetail() {
      await selectOperLogById(this.operationLogId).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.info = res.data;
        }
      });
    },
    handleClose() {
      this.$emit("closeDialogView");
    },
    sureClick() {
      this.$emit("closeDialogView");
    },
    cancelClick() {
      this.$emit("closeDialogView");
    },
  },
};
</script>

<style scoped>
.dialog-wrapper {
  width: 100%;
  /* height: 100%; */
}

.log-wrapper {
  width: 100%;
}

.line-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.content-wrapper {
  flex: 1; /* 让每个内容容器占据相同的空间，即一半 */
  display: flex;
}

.content-tile-wrapper {
  color: black;
  white-space: nowrap; /* 防止换行 */
}

.content {
  margin-left: 10px;
  color: #818386;
}
</style>