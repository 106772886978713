// 获取某天
export const getDay = function (value) {
    let time = new Date(value)
    // 分别获取年、月、日、小时、分钟和秒
    const year = time.getFullYear();
    const month = String(time.getMonth() + 1).padStart(2, '0');
    const day = String(time.getDate()).padStart(2, '0');
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
  
    // 拼接时间字符串
    const formattedTime = `${month}/${day}`;
  
    // console.log(formattedTime);
    return formattedTime
  
  }