import Vue from "vue";
import Router from "vue-router";
import login from "../views/Login.vue";
import home from "../views/Home.vue";
import dataStatistics from "../views/DataStatistics.vue";
import userManagement from "../views/UserManagement.vue";
import feedback from "@/views/Feedback.vue";
import loginLog from "@/views/LoginLog.vue";
import OperationLog from "@/views/OperationLog.vue";
import userInfo from "@/views/UserInfo.vue";


Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            redirect: "/login",
        },
        {
            path: "/login",
            name: "login",
            component: login,
        },
        {
            path: "/userInfo",
            name: "userInfo",
            component: userInfo,
        },
        {
            path: "/home",
            name: "home",
            component: home,
            redirect: "/userManagement",
            children:[
                {
                    path: "/dataStatistics",
                    name: "dataStatistics",
                    component: dataStatistics,
                },
                {
                    path: "/userManagement",
                    name: "userManagement",
                    component: userManagement,
                },
                {
                    path: "/feedback",
                    name: "feedback",
                    component: feedback,
                },
                {
                    path: "/loginLog",
                    name: "loginLog",
                    component: loginLog,
                },
                {
                    path: "/OperationLog",
                    name: "OperationLog",
                    component: OperationLog,
                },
            ]
        },
    ]
});
