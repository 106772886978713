import { request } from "@/requests";

export const selectLoginLogInfo = (data) => {
    return request({
        url: '/system/sysLoginInfo/selectLoginInfo',
        method: 'post',
        data:data
    })
}

export const cleanAllLoginInfo = (data) => {
    return request({
        url: '/system/sysLoginInfo/cleanLoginInfo',
        method: 'get'
    })
}

export const batchDeleteLog = (data) => {
    return request({
        url: '/system/sysLoginInfo/deleteLoginInfoByIds',
        method: 'post',
        data
    })
}