<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-logo">
        <img src="../assets/logo.png" />
      </div>
      <p class="login-tip">声誉通后台管理系统</p>
      <div class="login-border">
        <div class="login-main">
          <div style="width: 100%" class="login-phone">
            <div class="phone_wrapper">
              <i
                style="font-size: 20px; color: #40a2fe; margin-right: 10px"
                class="el-icon-phone"
              ></i>
              <el-input
                v-model="phone"
                placeholder="请输入手机号码"
                class="phone_input"
              />
            </div>
            <div class="code_wrapper" style="margin-top: 10px">
              <i
                style="font-size: 20px; color: #40a2fe; margin-right: 10px"
                class="el-icon-message"
              ></i>
              <el-input v-model="phoneCode" placeholder="请输入验证码">
                <template #append>
                  <el-button @click="startCountdown" :disabled="counting">{{
                    buttonOrCountdownText
                  }}</el-button>
                </template>
              </el-input>
            </div>
          </div>
          <!-- <div style="width: 100%" class="login-phone" v-if="flag == 1">
            <div class="phone_wrapper">
              <el-icon size="20" color="#40a2fe" class="el-icon-edit"></el-icon>
              <el-input
                v-model="account"
                placeholder="请输入账号"
                class="phone_input"
              />
            </div>
            <div class="code_wrapper" style="margin-top: 10px">
              <el-icon size="20" color="#40a2fe"><Lock /></el-icon>
              <el-input
                v-model="password"
                placeholder="请输入密码"
                type="password"
                show-password
              >
              </el-input>
            </div>
          </div> -->
          <div class="login_button_wrapper" @click="loginClick">
            <div class="login_button">登录</div>
          </div>
          <!-- <div class="login-menu">
            <div class="login_select" @click="selectLoginMethod(1)">
              账号密码
            </div>
            <div class="login_select" @click="selectLoginMethod(0)">
              手机号登录
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import preventBack from "vue-prevent-browser-back";
import { loginByPhone, getPhoneCode } from "@/api/login";
export default {
  name: "LoginView",
  data() {
    return {
      counting: false,
      countdown: 30, // 设置倒计时秒数
      flag: 0, //0-手机号登录 1-账号密码登录
      phone: "",
      phoneCode: "",
      account: "",
    };
  },
  mixins: [preventBack], //阻止返回上一页
  created() {},
  computed: {
    buttonOrCountdownText() {
      return this.counting ? `重新发送 ${this.countdown}s` : "发送验证码";
    },
  },
  methods: {
    startCountdown() {
      this.getphoneCode();
    },
    async getphoneCode() {
      let regex = /^1[3456789]\d{9}$/;
      if (!regex.test(this.phone)) {
        this.$message({
          message: "请填写正确的手机号码",
          type: "error",
        });
        return;
      }
      let param = {
        phoneNumber: this.phone,
        type: "login",
      };
      await getPhoneCode(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          if (!this.counting) {
            this.counting = true;
            const countdownInterval = setInterval(() => {
              this.countdown--;
              if (this.countdown < 0) {
                clearInterval(countdownInterval);
                this.counting = false;
                this.countdown = 60; // 重置倒计时
              }
            }, 1000);
          }
        }
      });
    },
    async loginClick() {
      await loginByPhone({
        phoneNumber: this.phone,
        smsCode: this.phoneCode,
      }).then(res=>{
        console.log(res);
        if(res.code == 200){
          localStorage.setItem("token", res.data.tokenValue);
          this.$router.replace("/home");
        }
      })
    },
  },
};
</script>

<style scoped>
/* .login-copyright */
.login-copyright {
  color: #999;
  width: 100%;
  position: fixed;
  bottom: 30px;
  text-align: center;
}

/* .login-container */
.login-container {
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: url("../assets/img/login.png");
  animation: animate-cloud 20s linear infinite;
}

/* .login-tip */
.login-tip {
  color: #409eff;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
}

/* .login-logo */
.login-logo {
  overflow: hidden;
  width: 110px;
  height: 110px;
  margin: -50px auto 20px auto;
  border-radius: 50%;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.07);
  padding: 10px;
  background-color: #fff;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
  padding: 20px;
}

.login-logo img {
  width: 100%;
}

/* .login-weaper */
.login-weaper {
  position: relative;
  margin: 0 auto;
  width: 380px;
  padding: 0 40px;
  box-sizing: border-box;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 3px;
}

.login-border {
  padding: 20px 0 40px 0;
  position: relative;
  align-items: center;
  display: flex;
}

/* .login-border */
.login-border {
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  background-color: #fff;
  width: 100%;
  float: left;
  box-sizing: border-box;
}

/* .login-main */
.login-main {
  margin: 0 auto;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
}

.phone_wrapper,
.code_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  /* border-bottom: 1px solid #ebedf2; */
}

.phone_input {
  border: none;
}

:deep(.el-input__wrapper) {
  box-shadow: none !important;
}

/* .login-menu */
.login-menu {
  margin-top: 40px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_select {
  color: #409eff;
  font-weight: 700;
  font-size: 12px;
  margin: 0px 8px;
  cursor: pointer;
}

.login_button_wrapper {
  width: 100%;
  height: 45px;
  font-size: 18px;
  letter-spacing: 5px;
  text-indent: 5px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 30px;
  font-family: "neo";
  transition: 0.25s;
  background-color: #409eff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

@keyframes animate-cloud {
  from {
    background-position: 600px 100%;
  }
  to {
    background-position: 0 100%;
  }
}
</style>